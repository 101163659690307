const fluxPositionDefault = 4;

export const defaultFlux = '0';

/**
 * detectActiveFlux
 * @description Detects active flux from the url param
 * @returns {number} Active flux
 */
export const detectActiveFlux = () => {
  let fluxPosition = fluxPositionDefault;
  const pathSpited = window.location.pathname.split('/');
  switch (pathSpited[1]) {
    case 'us':
      fluxPosition += 2;
      break;
    case 'partner':
      fluxPosition += 1;
      break;
    default:
      break;
  }
  return pathSpited[fluxPosition];
};

/**
 * getPartner
 * @description Get partner name by URL
 * @returns {number} Active partner
 */
export const getPartner = () => {
  const partnerPosition = 2;
  const pathSpited = window.location.pathname.split('/');
  switch (pathSpited[1]) {
    case 'us':
      return pathSpited[partnerPosition + 1];
    case 'partner':
      return pathSpited[partnerPosition];
    default:
      return null;
  }
};

/**
 * getPartnerOldLocation
 * @description Detects active partner from the url param
 * @returns {number} partner name in old location
 */
export const getPartnerOldLocation = () => {
  const pagePosition = 5;
  return window.location.pathname.split('/')[pagePosition];
};

/**
 * getStepsConfig
 * @description Return the steps configuration of a flux by index
 * @param {array} fluxes
 * @param {string} fluxIndex
 * @returns {array} Active flux
 */
export const getStepsConfig = (fluxes, fluxIndex) => {
  const selectedFlux = fluxes.find((flux) => flux.index === fluxIndex);

  return selectedFlux ? selectedFlux.steps : [];
};

/**
 * getIsSheetFlux
 * @description Return the steps configuration of a flux by index
 * @param {array} fluxes
 * @param {string} fluxIndex
 * @returns {boolean} Is sheet
 */
export const getIsSheetFlux = (fluxes, fluxIndex) => {
  const selectedFlux = fluxes.find((flux) => flux.index === fluxIndex);
  return selectedFlux?.isSheetFlux;
};

/**
 * getFluxIndex
 * @description Return the index of a flux
 * @param {array} fluxes
 * @param {string} fluxName
 * @returns {number} fluxIndex
 */
export const getFluxIndex = (fluxes, fluxName) => {
  const selectedFlux = fluxes.find((flux) => flux.name === fluxName);

  return selectedFlux ? selectedFlux.index : '0';
};
