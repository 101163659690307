import Axios from 'axios';
import { defaultFlux, getPartner } from '../../../utils/fluxes';
import { getToken } from '../../../utils/recaptcha';
import { getProductEndpoint } from '../../../utils/useProduct';
import { handleServerError } from '../../main/store/actions';
import history from '../../main/store/history';

import {
  BRANDS_LOAD,
  BRANDS_LOAD_FAIL,
  BRANDS_LOAD_SUCCESS,
  CATEGORIES_LOAD,
  CATEGORIES_LOAD_FAIL,
  CATEGORIES_LOAD_SUCCESS,
  DEPENDENT_LOAD,
  DEPENDENT_LOAD_FAIL,
  DEPENDENT_LOAD_SUCCESS,
  EXPENSES_LOAD,
  EXPENSES_LOAD_FAIL,
  EXPENSES_LOAD_SUCCESS,
  JUST_LOCALITIES_LOAD,
  JUST_LOCALITIES_LOAD_FAIL,
  JUST_LOCALITIES_LOAD_SUCCESS,
  MARKET_SECTOR_LOAD,
  MARKET_SECTOR_LOAD_FAIL,
  MARKET_SECTOR_LOAD_SUCCESS,
  MODELS_LOAD,
  MODELS_LOAD_FAIL,
  MODELS_LOAD_SUCCESS,
  PARTNER_CONFIG_LOAD,
  PARTNER_CONFIG_LOAD_FAIL,
  PARTNER_CONFIG_LOAD_SUCCESS,
  PROVINCES_LOAD,
  PROVINCES_LOAD_FAIL,
  PROVINCES_LOAD_SUCCESS,
  SURFACE_LOAD,
  SURFACE_LOAD_FAIL,
  SURFACE_LOAD_SUCCESS,
  VERSIONS_LOAD,
  VERSIONS_LOAD_FAIL,
  VERSIONS_LOAD_SUCCESS,
  YEARS_LOAD,
  YEARS_LOAD_FAIL,
  YEARS_LOAD_SUCCESS,
} from './service.action-types';

export const loadBrands = () => async (dispatch, getState) => {
  const { service, main } = getState();
  const { brand } = service;
  if (brand.brands && brand.brands.length) {
    return;
  }
  dispatch({
    type: BRANDS_LOAD,
  });
  const reCaptchaToken = await getToken(BRANDS_LOAD);
  const partner = getPartner();

  try {
    const res = await Axios.get(`/cars/brands`, {
      headers: {
        'x-recaptcha-token': reCaptchaToken,
        'x-recaptcha-action': BRANDS_LOAD,
        'x-partner': partner,
        'x-audit': main?.audit?.thumbmark,
      },
    });
    dispatch({
      type: BRANDS_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response?.status === 403) {
      history.push(`${main.baseUrl}/suspicious-behavior${window.location.search}`);
      return;
    }
    dispatch({
      type: BRANDS_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadYears = () => async (dispatch, getState) => {
  const { car, service } = getState();
  const { brand, lead } = car;
  const { year } = service;

  if (year.years.length > 0 && brand.reSelected) {
    return;
  }

  dispatch({ type: YEARS_LOAD });
  try {
    const res = await Axios.get(`/cars/years/${lead.selected.brand.id}`);

    dispatch({
      type: YEARS_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: YEARS_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadModels = () => async (dispatch, getState) => {
  const { car, service } = getState();
  const { brand, year, lead } = car;
  const partner = getPartner();

  /*
    Si recargas la pagina en el componente "modelo" se ejecuta este action antes de devolverte al primer step
    esta es una solucion temporal para que no rompa 
  */
  if (!brand) {
    return;
  }
  const { model } = service;

  if (model.models.length > 0 && brand.reSelected && year.reSelected) {
    return;
  }
  if (!lead.selected.brand.id || !lead.selected.year) {
    return;
  }

  dispatch({ type: MODELS_LOAD });
  const reCaptchaToken = await getToken(MODELS_LOAD);
  try {
    const res = await Axios.get(`/cars/models/${lead.selected.brand.id}/${lead.selected.year}`, {
      headers: {
        'x-recaptcha-token': reCaptchaToken,
        'x-recaptcha-action': MODELS_LOAD,
        'x-partner': partner,
      },
    });
    dispatch({
      type: MODELS_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MODELS_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadVersions = () => async (dispatch, getState) => {
  const { car } = getState();
  const { brand, year, model } = car.lead.selected;
  /*
    Si recargas la pagina en el componente "version" se ejecuta este action antes de devolverte al primer step
    esta es una solucion temporal para que no rompa 
  */
  if (!model) {
    return;
  }
  const modelname = model.name;
  dispatch({ type: VERSIONS_LOAD });
  const partner = getPartner();
  const reCaptchaToken = await getToken(VERSIONS_LOAD);

  try {
    const { data } = await Axios.get(`/cars/versions/${brand.id}/${year}/${model.id}`, {
      headers: {
        'x-recaptcha-token': reCaptchaToken,
        'x-recaptcha-action': VERSIONS_LOAD,
        'x-partner': partner,
      },
    });
    const clearModel = data.map((v) => ({
      id: v.id,
      name: v.name.replace(`de ${modelname}`, '').replace(modelname, '').trim(),
    }));
    dispatch({
      type: VERSIONS_LOAD_SUCCESS,
      payload: clearModel,
    });
  } catch (err) {
    dispatch({
      type: VERSIONS_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadCategories = () => async (dispatch) => {
  dispatch({
    type: CATEGORIES_LOAD,
  });
  try {
    const res = await Axios.get(`/business/categories`);
    dispatch({
      type: CATEGORIES_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleServerError());
    dispatch({
      type: CATEGORIES_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadMarketSector = () => async (dispatch, getState) => {
  const { business } = getState();
  const partner = getState().service.partnerConfig.data;
  const partnerKey = partner && partner.business.showOwnerMarketSectors ? partner.keyName : '';

  dispatch({
    type: MARKET_SECTOR_LOAD,
  });
  try {
    const res = await Axios.get(
      `/business/marketSector?category=${business.lead.selected.categories.id}&partnerKey=${partnerKey}`,
    );
    const payload = {
      ...res.data,
      options: res.data.options.sort((rubroA, rubroB) => (rubroA.id > rubroB.id ? 1 : -1)),
    };
    dispatch({
      type: MARKET_SECTOR_LOAD_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch(handleServerError());
    dispatch({
      type: MARKET_SECTOR_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadSurface = () => async (dispatch) => {
  dispatch({
    type: SURFACE_LOAD,
  });
  try {
    const res = await Axios.get(`/business/surfaces`);
    dispatch({
      type: SURFACE_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(handleServerError());
    dispatch({
      type: SURFACE_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadDependents = () => async (dispatch) => {
  dispatch({
    type: DEPENDENT_LOAD,
  });
  try {
    const res = await Axios.get(`/life/dependents`);
    dispatch({
      type: DEPENDENT_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEPENDENT_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadExpenses = () => async (dispatch) => {
  dispatch({
    type: EXPENSES_LOAD,
  });
  try {
    const res = await Axios.get(`/life/expenses`);
    dispatch({
      type: EXPENSES_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EXPENSES_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadJustLocalities = () => async (dispatch) => {
  dispatch({ type: JUST_LOCALITIES_LOAD });
  try {
    const res = await Axios.get(`/persons/localities`);
    dispatch({
      type: JUST_LOCALITIES_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: JUST_LOCALITIES_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadProvinces = () => async (dispatch) => {
  dispatch({ type: PROVINCES_LOAD });
  try {
    const res = await Axios.get(`/persons/provinces`);
    dispatch({
      type: PROVINCES_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROVINCES_LOAD_FAIL,
    });
    console.error(err);
  }
};

export const loadPartner = () => async (dispatch, getState) => {
  dispatch({ type: PARTNER_CONFIG_LOAD });
  const partner = getPartner();
  const product = getProductEndpoint(getState().wizard.product);
  const { baseUrl } = getState().main;
  if (!partner) {
    dispatch({
      type: PARTNER_CONFIG_LOAD_FAIL,
    });
    history.push(`${baseUrl}/${defaultFlux}${window.location.search}`);
    return;
  }

  try {
    const res = await Axios.get(`/${product}/partners/${partner}`);
    dispatch({
      type: PARTNER_CONFIG_LOAD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PARTNER_CONFIG_LOAD_FAIL,
    });
    if (err.response?.status === 404) {
      history.push(`${baseUrl}/not-found${window.location.search}`);
      return;
    }
    console.error(err);
    history.push(`${baseUrl}/${defaultFlux}${window.location.search}`);
  }
};

const actions = {
  loadBrands,
  loadYears,
  loadModels,
  loadVersions,
  loadCategories,
  loadMarketSector,
  loadSurface,
  loadDependents,
  loadExpenses,
  loadJustLocalities,
  loadProvinces,
  loadPartner,
};

export default actions;
