import { useTranslation } from 'react-i18next';
import i123n from '../i18n';
import { alpha2List } from './country-alpha2';
import { domainResolver } from './domain-resolver';
import { getPartner } from './fluxes';
import { getKeyByValue } from './object';

const REACT_APP_DEMO_TO_PRODUCT = process.env.REACT_APP_DEMO_TO_PRODUCT;

export const useProduct = () => {
  useTranslation('translation');
  let productName;
  let translations;
  const country = domainResolver();

  let productPosition = getPartner() ? 3 : 2;
  if (country === alpha2List.US) {
    productPosition += 1;
  }
  productName = window.location.pathname.split('/')[productPosition];
  translations = i123n.getDataByLanguage(country).translation;

  if (productName && productName === REACT_APP_DEMO_TO_PRODUCT) {
    return 'demo';
  }
  return productName ? getKeyByValue(translations.products, productName) : null;
};

/**
 * getProductEndpoint
 * @description Return used product for endpoint
 * @param {string} product
 * @returns {string} product for endpoint
 */
export const getProductEndpoint = (product) => {
  switch (product) {
    case 'car':
      return 'cars';
    case 'business':
      return 'business';
    case 'telecare':
      return 'telecare';
    case 'life':
      return 'life';
    case 'personalAccidents':
      return 'personal-accidents';
    case 'lifePrudentialUs':
      return 'life-prudential-us';
    default:
      return '';
  }
};
