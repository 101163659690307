export const risk = {
  ar: {
    car: {
      riesgo: 'auto',
      riesgo_id: 1,
    },
    life: {
      riesgo: 'vida',
      riesgo_id: 0,
    },
    telecare: {
      riesgo: 'teleasistencia',
      riesgo_id: 0,
    },
    business: {
      riesgo: 'comercio',
      riesgo_id: 'business',
    },
  },
  cl: {
    car: {
      riesgo: 'auto',
      riesgo_id: 1,
    },
    life: {
      riesgo: 'vida',
      riesgo_id: 0,
    },
  },
  co: {
    car: {
      riesgo: 'auto',
      riesgo_id: 1,
    },
    moto: {
      riesgo: 'moto',
      riesgo_id: 10,
    },
    life: {
      riesgo: 'vida',
      riesgo_id: 0,
    },
  },
  br: {
    car: {
      riesgo: 'auto',
      riesgo_id: 1,
    },
  },
  // TODO: GA configuration
  mx: {
    demo: {
      riesgo: 'demo',
      riesgo_id: 1,
    },
  },
  us: {
    lifePrudentialUs: {
      riesgo: 'demo',
      riesgo_id: 1,
    },
  },
};

export const commonKeysInAdditionalData = [
  'riesgo',
  'riesgo_id',
  'marca',
  'marca_id',
  'modelo',
  'modelo_id',
  'version',
  'version_id',
  '0km',
  'anio',
  'departamento',
  'departamento_id',
  'barrio',
  'barrio_id',
  'edad',
];
