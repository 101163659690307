/** @jsxRuntime classic */
/** @jsx jsx */
import { colors, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { StepHeader } from '../../main/components/StepHeader';
import { layout } from '../../main/styles/layout';

const styles = (backgroundImageUrl) => ({
  ...layout,
  backgroundImage: `url('${backgroundImageUrl}')`,
  backgroundPositionY: '-27vh',
  backgroundSize: 'cover',

  display: 'flex',
  alignItems: 'start',
  textAlign: 'center',
  justifyContent: 'center',

  [respondTo('sm')]: {
    height: '110vh',
  },
  [respondTo('md')]: {
    paddingTop: 120,
  },
  [respondTo('lg')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 24% 2fr 30% 1fr',
    justifyItems: 'end',
    height: '100vh',
  },
  margin: 0,
  '> .container': {
    gridColumn: 4,
    maxWidth: 640,
    borderRadius: 5,
    backgroundColor: 'white',
    padding: 40,

    [respondTo('xs', 'sm')]: {
      height: '100vh',
    },
    [respondTo('md')]: {
      height: 490,
      justifyContent: 'center',
    },
    [respondTo('lg')]: {
      minWidth: 500,
    },

    margin: 0,
    '.subtitle': {
      color: colors.gray.darkest,
    },
  },
  '.title-section': {
    paddingTop: 32,
    [respondTo('xs', 'sm')]: {
      paddingTop: 16,
    },
  },
  '.phone-subtitle': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '1.2em',
    margin: 5,
  },
  '.phone-number': {
    color: colors.insurance.prudential,
    fontSize: 20,
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export const CheckoutSuccessV2 = () => {
  const { t, exists } = useQuotationTranslation();
  const iconHeight = t('checkout.success.iconHeight', undefined);
  const iconStyle = iconHeight && { height: iconHeight };
  const backgroundImage = 'https://branding.123seguro.com/images/img_wizard_background.jpg';

  return (
    <section css={styles(backgroundImage)}>
      <div className="container">
        <img
          className="success-image"
          alt="success"
          src={t('checkout.success.iconUrl')}
          style={iconStyle}
        />
        <StepHeader title={t('checkout.success.title')} subtitle={t('checkout.success.subtitle')} />
        {exists('checkout.success.phoneSubtitle') && (
          <div className="container">
            <h2 className="phone-subtitle">{t('checkout.success.phoneSubtitle')}</h2>
            <p>
              <a
                className="phone-number"
                href={`tel:${t('checkout.success.phoneNumber').replace(/[^0-9]/g, '')}`}
              >
                {t('checkout.success.phoneNumber')}
              </a>
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
