/** @jsxRuntime classic */
/** @jsx jsx */
import { colors, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPartner } from '../../../utils/fluxes';
import { useProduct } from '../../../utils/useProduct';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { StepHeader } from '../../main/components/StepHeader';
import { layout } from '../../main/styles/layout';
import { loadPartner } from '../../service/store/service.actions';
import { saveProduct } from '../../wizard/store/wizard.actions';
import { CheckoutSuccessV2 } from './CheckoutSuccessV2';
import CheckoutSuccessPrudentialUs from './CheckoutSuccessPrudentialUs';

const styles = {
  ...layout,
  textAlign: 'center',
  marginTop: 64,
  '.container': {
    maxWidth: 380,
    margin: '0 auto',
    '.subtitle': {
      color: colors.gray.darkest,
    },
  },
  '.title-section': {
    paddingTop: 32,
    [respondTo('xs', 'sm')]: {
      paddingTop: 16,
    },
  },
  '.phone-subtitle': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '1.2em',
    margin: 5,
  },
  '.phone-number': {
    color: colors.insurance.prudential,
    fontSize: 20,
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'none',
    },
  },
};

const CheckoutSuccess = () => {
  const { t, exists } = useQuotationTranslation();
  const iconHeight = t('checkout.success.iconHeight', undefined);
  const iconStyle = iconHeight && { height: iconHeight };

  return (
    <section css={styles}>
      <img
        className="success-image"
        alt="success"
        src={t('checkout.success.iconUrl')}
        style={iconStyle}
      />
      <StepHeader title={t('checkout.success.title')} subtitle={t('checkout.success.subtitle')} />
      {exists('checkout.success.phoneSubtitle') && (
        <div className="container">
          <h2 className="phone-subtitle">{t('checkout.success.phoneSubtitle')}</h2>
          <p>
            <a
              className="phone-number"
              href={`tel:${t('checkout.success.phoneNumber').replace(/[^0-9]/g, '')}`}
            >
              {t('checkout.success.phoneNumber')}
            </a>
          </p>
        </div>
      )}
    </section>
  );
};

const CheckoutSuccessComponentSelector = ({
  partnerConfig,
  loadPartner,
  productSaved,
  saveProduct,
}) => {
  const partner = getPartner();
  const product = useProduct();

  useEffect(() => {
    if (!productSaved) {
      saveProduct(product);
    }
  }, [productSaved, saveProduct, product]);

  useEffect(() => {
    if (productSaved && partner && !partnerConfig) {
      loadPartner();
    }
  }, [partnerConfig, partner, loadPartner, productSaved]);

  if (product === 'lifePrudentialUs') {
    return <CheckoutSuccessPrudentialUs />;
  }

  if (partner === 'serasa') {
    return <CheckoutSuccessV2 />;
  }

  return <CheckoutSuccess />;
};

const mapStateToProps = (state) => ({
  partnerConfig: state.service.partnerConfig.data,
  productSaved: state.wizard.product,
});

CheckoutSuccessComponentSelector.propTypes = {
  partnerConfig: PropTypes.object,
  loadPartner: PropTypes.func,
  productSaved: PropTypes.string,
  saveProduct: PropTypes.func,
};

export default connect(mapStateToProps, { loadPartner, saveProduct })(
  CheckoutSuccessComponentSelector,
);
