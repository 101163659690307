import { useSelector } from 'react-redux';
import { defaultFlux, detectActiveFlux, getStepsConfig } from '../../../utils/fluxes';

export const getStepsList = (steps) => {
  return steps
    .map((module) => {
      return module.moduleSteps;
    })
    .flat();
};

export const getModuleName = (steps, s) => {
  const module = steps.find((_module) => {
    return _module.moduleSteps.find((step) => step === s);
  });
  return module && module.moduleName;
};

/**
 * useActiveFluxSteps
 * @description custom hook that returns the steps of the active flux
 * @returns {array} steps
 */
export const useActiveFluxSteps = () => {
  const fluxes = useSelector((state) => state.wizard.fluxes);
  const activeFlux = detectActiveFlux() || defaultFlux;

  return getStepsConfig(fluxes, activeFlux) || [];
};
