import { alpha2List } from './country-alpha2';

const defaultLanguage = alpha2List.AR;
const validAlphas = [...Object.values(alpha2List)];

const isUnitedStates = () => {
  return window.location.pathname.startsWith('/us/');
};
/**
 * Resolve the current domain (also works on server side rendering)
 * @returns string
 */
export const domainResolver = () => {
  if (!window) {
    return defaultLanguage;
  }

  if (isUnitedStates()) {
    return alpha2List.US;
  }

  const hostname = window.location.hostname.split('.');

  const domain = validAlphas.find((alpha) =>
    hostname.some((token) => {
      let urlAlpha;
      if (token === 'dev-br' || token === 'test-br' || token === 'uat-br') {
        urlAlpha = 'br';
      } else {
        urlAlpha = token;
      }
      return urlAlpha === alpha;
    }),
  );

  if (!validAlphas.includes(domain)) {
    return defaultLanguage;
  }
  return domain;
};
