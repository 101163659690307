/** @jsxRuntime classic */
/** @jsx jsx */
import { colors, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { StepHeader } from '../../main/components/StepHeader';
import { layout } from '../../main/styles/layout';
import '../../personalAccidents/fonts.css';

const styles = {
  ...layout,
  textAlign: 'center',
  marginTop: 64,
  '.container': {
    maxWidth: 380,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    '.subtitle': {
      color: '#DDDDD',
    },
  },
  'h1.title-section': {
    paddingTop: 32,
    fontFamily: 'PrudentialModern',
    [respondTo('xs', 'sm')]: {
      paddingTop: 16,
    },
  },
  '.phone-subtitle': {
    fontSize: 16,
    color: '#777777',
    fontWeight: 400,
    lineHeight: '1.2em',
    margin: 5,
  },
  '.phone-number': {
    color: colors.insurance.prudential,
    fontSize: 20,
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  '.self-service-link': {
    color: '#007AC1',
    fontWeight: 700,
    fontSize: '1.2em',
    display: 'flex',
    justifyContent: 'center',
    gap: '0.2em',
    textDecoration: 'underline',
    alignItems: 'center',
  },
  '.self-service-link-image': {
    width: '1.5em',
  },
  '.separator': {
    marginTop: '1.5em',
    marginBottom: '1em',
    alignSelf: 'center',
    width: '300px',
    height: '2px',
    backgroundColor: '#DDDDDD',
  },
};

const CheckoutSuccessPrudentialUs = () => {
  const { t, exists } = useQuotationTranslation();
  const iconHeight = t('checkout.success.iconHeight', undefined);
  const iconStyle = iconHeight && { height: iconHeight };

  return (
    <section css={styles}>
      <img
        className="success-image"
        alt="success"
        src={t('checkout.success.iconUrl')}
        style={iconStyle}
      />
      <StepHeader title={t('checkout.success.title')} subtitle={t('checkout.success.subtitle')} />
      {exists('checkout.success.phoneSubtitle') && (
        <div className="container">
          <h2 className="phone-subtitle">{t('checkout.success.phoneSubtitle')}</h2>
          <p>
            <a
              className="phone-number"
              href={`tel:${t('checkout.success.phoneNumber').replace(/[^0-9]/g, '')}`}
            >
              {t('checkout.success.phoneNumber')}
            </a>
          </p>
          <div className="separator" />
          <p className="subtitle">{t('checkout.success.managePolicy')}</p>
          <a className="self-service-link" href="/us/self-service">
            {t('checkout.success.goTo123App')}
            <img
              className="self-service-link-image"
              alt="success"
              src={t('checkout.success.selfServiceIconUrl')}
              style={iconStyle}
            />
          </a>
        </div>
      )}
    </section>
  );
};

export default CheckoutSuccessPrudentialUs;
