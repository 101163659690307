import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getPartnerOldLocation } from '../../utils/fluxes';
import { useBaseUrl } from '../../utils/useBaseUrl';
import { useProduct } from '../../utils/useProduct';
import Experiment from '../car/experiment';
import InvalidYear from '../car/invalidYear';
import NoResults from '../car/noresults';
import FailedChecklist from '../person/failedChecklist/FailedChecklist';
import Wizard from '../wizard/wizard';
import CheckoutSuccessComponentSelector from './components/CheckoutSuccess';
import InConstruction from './components/InConstruction';
import Page403 from './components/Page403';
import Page404 from './components/Page404';
import { Page500 } from './components/Page500';
import { saveBaseUrl } from './store/actions';

const partnerNameOldLocation = getPartnerOldLocation();

const MainRouter = ({ saveBaseUrl }) => {
  const product = useProduct();
  const baseUrl = useBaseUrl();
  const { t } = useTranslation();

  const experimentPaths = ['/seguro/:product/experiment', '/seguros/:product/experiment'];

  const paths = [
    '/us/partner/:partner/:product/quote',
    '/us/partner/:partner/:product/quote/:flux',
    '/us/partner/:partner/:product/quote/:flux/:step',
    '/seguros/:product/cotizar',
    '/seguros/:product/cotizar/:flux',
    '/seguros/:product/cotizar/:flux/:step',
    '/seguro/:product/cotar',
    '/seguro/:product/cotar/:flux',
    '/seguro/:product/cotar/:flux/:step',
    '/partner/:partner/:product/cotizar',
    '/partner/:partner/:product/cotizar/:flux',
    '/partner/:partner/:product/cotizar/:flux/:step',
    '/partner/:partner/:product/cotar',
    '/partner/:partner/:product/cotar/:flux',
    '/partner/:partner/:product/cotar/:flux/:step',
  ];
  const pathToRedirect = [
    '/seguros/:product/cotizar/partners/:partner',
    '/seguro/:product/cotar/partners/:partner',
    '/seguro/:product/quote/partners/:partner',
  ];
  const toRedirect = [
    `/partner/${partnerNameOldLocation}/${t(`products.${product}`)}/${t(`quote`)}${
      window.location.search
    }`,
    `/partner/${partnerNameOldLocation}/${t(`products.${product}`)}/${t(`quote`)}${
      window.location.search
    }`,
  ];
  useEffect(() => {
    saveBaseUrl(baseUrl);
  }, [baseUrl, saveBaseUrl]);

  return (
    <Switch>
      <Route exact path={`${baseUrl}/error`} component={Page500} />
      <Route exact path={`${baseUrl}/suspicious-behavior`} component={Page403} />
      <Route exact path={`${baseUrl}/quotation-noresults`} component={NoResults} />
      <Route exact path={`${baseUrl}/vehicle-antique-car`} component={InvalidYear} />
      <Route exact path={`${baseUrl}/failed-checklist`} component={FailedChecklist} />
      <Route path={`${baseUrl}/not-found`} component={Page404} />
      <Route path={`${baseUrl}/en_construccion`} component={InConstruction} />
      <Route path={`${baseUrl}/checkout-thanks`} component={CheckoutSuccessComponentSelector} />
      <Route path={`${baseUrl}/checkout-success`} component={CheckoutSuccessComponentSelector} />

      {pathToRedirect.map((path, index) => (
        <Route exact path={path} key={index}>
          <Redirect to={toRedirect[index]} />
        </Route>
      ))}

      <Route exact path={experimentPaths} component={Experiment} />
      <Route exact path={paths} render={(props) => <Wizard {...props} product={product} />} />
      <Route component={Page404} />
    </Switch>
  );
};

MainRouter.propTypes = {
  saveBaseUrl: PropTypes.func,
};

export default connect(null, { saveBaseUrl })(MainRouter);
